.publicationsComponent {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.publicationsComponent h1 {
  color: #ffffff;
  align-self: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
}

.publicationsController {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: center;
}

.filterController {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterController p {
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #a2afc0;
}

.filterController .selected {
  color: #5389ed;
}

.orderController {
  display: flex;
}

.orderController p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
}

.orderLabel {
  margin: 0;
  color: #a2afc0;
}

.orderValue {
  margin: 0;
  cursor: pointer;
  color: #5389ed;
}

.publicationsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .publicationsComponent {
    margin-top: 80px;
    padding: 2vh 2vw;
    padding-bottom: 200px;
  }

  .publicationsComponent h1 {
    font-size: 22px;
    line-height: 32px;
  }

  .publicationsController {
    margin-bottom: 20px;
    flex-direction: column;
  }

  .filterController {
    width: 100%;
    flex-wrap: wrap;
  }

  .filterController p {
    margin: 0 5px;
    font-size: 14px;
    line-height: 27px;
  }

  .orderController {
    margin-top: 10px;
    margin-left: 20px;
    align-self: flex-start;
  }

  .orderController p {
    font-size: 14px;
    line-height: 27px;
  }

  .orderValue {
    margin-left: 8px;
  }

  .publicationsContainer {
    margin-top: 10px;
  }
}

@media screen and (min-width: 481px) and (max-width: 560px) {
  .publicationsComponent {
    margin-top: 100px;
    padding: 2vh 2vw;
    padding-bottom: 200px;
  }

  .publicationsComponent h1 {
    font-size: 25px;
    line-height: 40px;
  }

  .publicationsController {
    margin-bottom: 20px;
    flex-direction: column;
  }

  .filterController {
    width: 100%;
    flex-wrap: wrap;
  }

  .filterController p {
    margin: 0 5px;
    font-size: 14px;
    line-height: 27px;
  }

  .orderController {
    margin-top: 10px;
    margin-left: 20px;
    align-self: flex-start;
  }

  .orderController p {
    font-size: 14px;
    line-height: 27px;
  }

  .orderValue {
    margin-left: 8px;
  }

  .publicationsContainer {
    margin-top: 10px;
    min-height: 50vh;
  }
}

@media screen and (min-width: 561px) and (max-width: 670px) {
  .publicationsComponent {
    margin-top: 100px;
    padding: 2vh 2vw;
    padding-bottom: 200px;
  }

  .publicationsComponent h1 {
    font-size: 30px;
    line-height: 40px;
  }

  .publicationsController {
    margin-bottom: 20px;
    flex-direction: column;
  }

  .filterController {
    width: 100%;
    flex-wrap: wrap;
  }

  .filterController p {
    margin: 0 5px;
    font-size: 17px;
    line-height: 30px;
  }

  .orderController {
    margin-top: 10px;
    margin-left: 20px;
    align-self: flex-start;
  }

  .orderController p {
    font-size: 17px;
    line-height: 30px;
  }

  .orderValue {
    margin-left: 8px;
  }

  .publicationsContainer {
    margin-top: 10px;
    min-height: 50vh;
  }
}

@media screen and (min-width: 671px) and (max-width: 800px) {
  .publicationsComponent {
    margin-top: 100px;
    padding: 2vh 2vw;
    padding-bottom: 200px;
  }

  .publicationsComponent h1 {
    font-size: 32px;
    line-height: 40px;
  }

  .publicationsController {
    margin-bottom: 20px;
    flex-direction: column;
  }

  .filterController {
    width: 100%;
    flex-wrap: wrap;
  }

  .filterController p {
    margin: 0 10px;
    font-size: 19px;
    line-height: 32px;
  }

  .orderController {
    margin-top: 10px;
    margin-left: 20px;
    align-self: flex-start;
  }

  .orderController p {
    font-size: 19px;
    line-height: 32px;
  }

  .orderValue {
    margin-left: 8px;
  }

  .publicationsContainer {
    margin-top: 10px;
    min-height: 50vh;
  }
}

@media screen and (min-width: 801px) and (max-width: 930px) {
  .publicationsComponent {
    margin-top: 100px;
    padding: 2vh 2vw;
    padding-bottom: 200px;
  }

  .publicationsComponent h1 {
    font-size: 32px;
    line-height: 40px;
  }

  .publicationsController {
    margin-bottom: 20px;
    flex-direction: column;
  }

  .filterController {
    width: 100%;
    flex-wrap: wrap;
  }

  .filterController p {
    margin: 0 12px;
    font-size: 22px;
    line-height: 35px;
  }

  .orderController {
    margin-top: 10px;
    margin-left: 20px;
    align-self: flex-start;
  }

  .orderController p {
    font-size: 22px;
    line-height: 35px;
  }

  .orderValue {
    margin-left: 8px;
  }

  .publicationsContainer {
    margin-top: 10px;
    min-height: 50vh;
  }
}

@media screen and (min-width: 931px) and (max-width: 1023px) {
  .publicationsComponent {
    margin-top: 100px;
    padding: 2vh 2vw;
    padding-bottom: 200px;
  }

  .publicationsComponent h1 {
    font-size: 35px;
    line-height: 50px;
  }

  .publicationsController {
    margin-bottom: 20px;
    flex-direction: column;
  }

  .filterController {
    width: 100%;
    flex-wrap: wrap;
  }

  .filterController p {
    margin: 0 5px;
    font-size: 17px;
    line-height: 30px;
  }

  .orderController {
    margin-top: 10px;
    margin-left: 5px;
    align-self: flex-start;
  }

  .orderController p {
    font-size: 17px;
    line-height: 30px;
  }

  .orderValue {
    margin-left: 8px;
  }

  .publicationsContainer {
    margin-top: 10px;
    min-height: 50vh;
  }
}

@media screen and (min-width: 1024px) {
  .publicationsComponent {
    margin-top: 100px;
    min-width: 1000px;
    padding: 2vh 2vw;
    padding-bottom: 200px;
  }

  .publicationsController {
    margin-bottom: 20px;
  }

  .orderValue {
    margin-left: 10px;
  }

  .publicationsContainer {
    padding-top: 10px;
    height: 75vh;
    overflow-y: scroll;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1324px) {
  .publicationsComponent h1 {
    font-size: 40px;
    line-height: 60px;
    width: 1000px;
  }

  .publicationsController {
    width: 1000px;
  }
  .filterController p {
    margin: 0 10px;
    font-size: 14px;
    line-height: 25px;
  }

  .orderController p {
    font-size: 14px;
    line-height: 25px;
  }
}

@media screen and (min-width: 1325px) and (max-width: 1599px) {
  .publicationsComponent h1 {
    font-size: 45px;
    line-height: 65px;
    width: 1250px;
  }

  .publicationsController {
    width: 1250px;
  }
  .filterController p {
    margin: 0 20px;
    font-size: 16px;
    line-height: 25px;
  }

  .orderController p {
    font-size: 16px;
    line-height: 25px;
  }
}

@media screen and (min-width: 1600px) {
  .publicationsComponent h1 {
    font-size: 50px;
    line-height: 70px;
    width: 1450px;
  }

  .publicationsController {
    width: 1450px;
  }

  .filterController p {
    margin: 0 20px;
    font-size: 18px;
    line-height: 27px;
  }

  .orderController p {
    font-size: 18px;
    line-height: 27px;
  }
}
