.contactComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.contactComponent h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
}

.contactComponent h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
}

.contactForm {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.doubleInputRow {
  width: 100%;
  display: grid;
}

.singleInputRow {
  width: 100%;
  display: grid;
  grid-template-columns: 100fr;
}

.contactForm label {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  color: #ffffff;
}

.contactForm label input,
.contactForm label select {
  display: block;
  padding: 12px 20px;
  box-sizing: border-box;
  background: rgba(41, 46, 71, 0.4);
  border-radius: 28px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #a2afc0;
}

.contactForm label textarea {
  resize: none;
  box-sizing: border-box;
  background: rgba(41, 46, 71, 0.4);
  border-radius: 28px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #a2afc0;
}

.contactForm label input::placeholder,
.contactForm label select::placeholder,
.contactForm label textarea::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #a2afc0;
}

.gradResearchNoteContainer,
.undergradResearchNoteContainer,
.lorRequestNoteContainer {
  display: flex;
  width: 100%;
}

.gradResearchNoteContainer p,
.undergradResearchNoteContainer p,
.lorRequestNoteContainer p {
  text-align: left;
  font-style: italic;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #a2afc0;
}

.contactFormSubmitButton {
  border: none;
  cursor: pointer;
  transition-duration: 0.5s;
  background: #5389ed;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactFormSubmitButton:hover {
  background-color: #2167e9;
}

.contactFormSubmitButton p {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
}

.zoomMeetingDiv {
  position: absolute;
  bottom: 50px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  background: #292e47;
  border-radius: 29px 0px 0px 29px;
}

.zoomMeetingDiv .message {
  align-self: flex-start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #dcddf0;
  opacity: 0.7;
}

.zoomMeetingDiv .secondLineMessage {
  font-weight: 600;
  opacity: 1;
}

.zoomMeetingDiv img {
  width: auto;
}

.calendlyModalTriggerButton {
  position: absolute;
  border: none;
  cursor: pointer;
  transition-duration: 0.5s;
  outline: none;
  background: #5389ed;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendlyModalTriggerButton:hover {
  background-color: #2167e9;
}

.calendlyModalTriggerButton p {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
}

.formSubmitMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formSubmitMessage h1 {
  margin-top: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
}

.formSubmitMessage .message {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
}

/* prevents styling change on form element focus */
textarea:focus,
select:focus,
input:focus {
  outline: none;
}

/* prevents styling change due to Google Chrome autofill */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

@media screen and (max-width: 480px) {
  .contactComponent {
    padding-top: 90px;
    min-height: 1250px;
    padding-bottom: 100px;
  }

  .contactComponent h1 {
    font-size: 22px;
    line-height: 32px;
  }

  .contactComponent h2 {
    width: 70vw;
    text-align: center;
    font-size: 14px;
    line-height: 150%;
  }

  .contactForm {
    padding-top: 3vh;
    width: 85vw;
  }

  .doubleInputRow {
    display: flex;
    flex-direction: column;
  }

  .singleInputRow {
    margin-bottom: 20px;
  }

  .contactForm label {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }

  .contactForm label input,
  .contactForm label select {
    height: 45px;
    margin-top: 5px;
    font-size: 12px;
    line-height: 150%;
    width: 100%;
  }

  .contactForm label textarea {
    margin-top: 5px;
    width: 100%;
    height: 144px;
    padding: 12px 20px;
    margin: 8px 0;
    font-size: 12px;
    line-height: 150%;
  }

  .contactForm label input::placeholder,
  .contactForm label select::placeholder,
  .contactForm label textarea::placeholder {
    font-size: 12px;
    line-height: 150%;
  }

  .gradResearchNoteContainer,
  .undergradResearchNoteContainer,
  .lorRequestNoteContainer {
    margin-bottom: 20px;
  }

  .gradResearchNoteContainer p,
  .undergradResearchNoteContainer p,
  .lorRequestNoteContainer p {
    font-size: 12px;
    line-height: 150%;
  }

  .contactFormSubmitButton {
    width: 160px;
    height: 50px;
  }

  .contactFormSubmitButton p {
    font-size: 14px;
  }

  .zoomMeetingDiv {
    margin-top: 100px;
    padding: 20px;
    width: 90vw;
    height: 275px;
    position: relative;
    border-radius: 29px;
  }

  .zoomMeetingDiv .message {
    margin: 0;
    font-size: 14px;
    line-height: 150%;
  }

  .zoomMeetingDiv img {
    margin-top: 25px;
    height: 75px;
  }

  .calendlyModalTriggerButton {
    bottom: 15px;
    width: 200px;
    height: 50px;
  }

  .calendlyModalTriggerButton p {
    font-size: 14px;
  }

  .formSubmitMessage {
    width: 90vw;
    height: 70vh;
  }

  .formSubmitMessage h1 {
    font-size: 20px;
  }

  .formSubmitMessage .message {
    margin-top: 30px;
    margin-bottom: 80px;
    font-size: 14px;
  }
}

@media screen and (min-width: 481px) and (max-width: 700px) {
  .contactComponent {
    padding-top: 100px;
    min-height: 1250px;
    padding-bottom: 100px;
  }

  .contactComponent h1 {
    font-size: 30px;
    line-height: 42px;
  }

  .contactComponent h2 {
    font-size: 20px;
    line-height: 34px;
  }

  .contactForm {
    padding-top: 3vh;
    width: 85vw;
  }

  .doubleInputRow {
    display: flex;
    flex-direction: column;
  }

  .singleInputRow {
    margin-bottom: 20px;
  }

  .contactForm label {
    font-weight: 500;
    font-size: 15px;
    line-height: 31px;
  }

  .contactForm label input,
  .contactForm label select {
    height: 60px;
    margin-top: 5px;
    font-size: 15px;
    line-height: 30px;
  }

  .contactForm label input {
    width: 100%;
  }

  .contactForm label select {
    width: 100%;
  }

  .contactForm label textarea {
    margin-top: 5px;
    width: 100%;

    height: 216px;
    padding: 12px 20px;
    margin: 8px 0;
    font-size: 15px;
    line-height: 45px;
  }

  .contactForm label input::placeholder,
  .contactForm label select::placeholder,
  .contactForm label textarea::placeholder {
    font-size: 15px;
    line-height: 30px;
  }

  .gradResearchNoteContainer,
  .undergradResearchNoteContainer,
  .lorRequestNoteContainer {
    margin-bottom: 20px;
  }

  .gradResearchNoteContainer p,
  .undergradResearchNoteContainer p,
  .lorRequestNoteContainer p {
    font-size: 15px;
    line-height: 30px;
  }

  .contactFormSubmitButton {
    width: 170px;
    height: 60px;
  }

  .contactFormSubmitButton p {
    font-size: 16px;
  }

  .zoomMeetingDiv {
    margin-top: 100px;
    padding: 20px;
    width: 450px;
    height: 300px;
    position: relative;
    border-radius: 29px;
  }

  .zoomMeetingDiv .message {
    font-size: 16px;
    line-height: 30px;
  }

  .zoomMeetingDiv img {
    margin-top: 0px;
    height: 90px;
  }

  .calendlyModalTriggerButton {
    bottom: 15px;
    width: 175px;
    height: 50px;
  }

  .calendlyModalTriggerButton p {
    font-size: 14px;
  }

  .formSubmitMessage {
    width: 70vw;
    height: 800px;
  }

  .formSubmitMessage h1 {
    font-size: 36px;
  }

  .formSubmitMessage .message {
    margin-top: 50px;
    margin-bottom: 100px;
    font-size: 24px;
  }
}

@media screen and (min-width: 701px) and (max-width: 1023px) {
  .contactComponent {
    padding-top: 100px;
    min-height: 75vh;
    padding-bottom: 450px;
  }
  .contactComponent h1 {
    font-size: 40px;
    line-height: 56px;
  }

  .contactComponent h2 {
    font-size: 24px;
    line-height: 33px;
  }

  .contactForm {
    padding-top: 3vh;
    width: 90vw;
  }

  .doubleInputRow {
    grid-template-columns: 44vw 44vw;
    grid-column-gap: 2vw;
    margin-bottom: 20px;
  }

  .singleInputRow {
    margin-bottom: 20px;
  }

  .contactForm label {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
  }

  .contactForm label input,
  .contactForm label select {
    height: 60px;
    margin-top: 5px;
    font-size: 13px;
    line-height: 36px;
  }

  .contactForm label input {
    width: 44vw;
  }

  .contactForm label select {
    width: 90vw;
  }

  .contactForm label textarea {
    margin-top: 5px;
    width: 90vw;
    height: 297px;
    padding: 12px 20px;
    margin: 8px 0;
    font-size: 13px;
    line-height: 36px;
  }

  .contactForm label input::placeholder,
  .contactForm label select::placeholder,
  .contactForm label textarea::placeholder {
    font-size: 13px;
    line-height: 26px;
  }

  .gradResearchNoteContainer,
  .undergradResearchNoteContainer,
  .lorRequestNoteContainer {
    margin-bottom: 30px;
  }

  .gradResearchNoteContainer p,
  .undergradResearchNoteContainer p,
  .lorRequestNoteContainer p {
    font-size: 14px;
    line-height: 26px;
  }

  .contactFormSubmitButton {
    width: 166.65px;
    height: 50px;
  }

  .contactFormSubmitButton p {
    font-size: 18px;
  }

  .zoomMeetingDiv {
    padding: 20px;
    width: 448px;
    height: 350px;
  }

  .zoomMeetingDiv .message {
    font-size: 16px;
    line-height: 30px;
  }

  .zoomMeetingDiv img {
    margin-top: 20px;
    height: 90px;
  }

  .calendlyModalTriggerButton {
    bottom: 15px;
    width: 275px;
    height: 58px;
  }

  .calendlyModalTriggerButton p {
    font-size: 18px;
  }

  .formSubmitMessage {
    width: 75vw;
    height: 60vh;
  }

  .formSubmitMessage h1 {
    font-size: 28px;
  }

  .formSubmitMessage .message {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 18px;
  }
}

@media screen and (min-width: 1024px) {
  .formSubmitMessage {
    width: 70vw;
    height: 800px;
  }

  .formSubmitMessage h1 {
    font-size: 36px;
  }

  .formSubmitMessage .message {
    margin-top: 50px;
    margin-bottom: 100px;
    font-size: 24px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1299px) {
  .contactComponent {
    padding-top: 100px;
    min-height: 75vh;
    padding-bottom: 450px;
  }
  .contactComponent h1 {
    font-size: 50px;
    line-height: 70px;
  }

  .contactComponent h2 {
    font-size: 35px;
    line-height: 50px;
  }

  .contactForm {
    padding-top: 3vh;
    width: 900px;
  }

  .doubleInputRow {
    grid-template-columns: 435px 435px;
    grid-column-gap: 30px;
    margin-bottom: 20px;
  }
  .singleInputRow {
    margin-bottom: 20px;
  }

  .contactForm label {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  .contactForm label input,
  .contactForm label select {
    height: 90px;
    margin-top: 5px;
    font-size: 18px;
    line-height: 36px;
  }

  .contactForm label input {
    width: 435px;
  }

  .contactForm label select {
    width: 900px;
  }

  .contactForm label textarea {
    margin-top: 5px;
    width: 900px;
    height: 297px;
    padding: 12px 20px;
    margin: 8px 0;
    font-size: 18px;
    line-height: 36px;
  }

  .contactForm label input::placeholder,
  .contactForm label select::placeholder,
  .contactForm label textarea::placeholder {
    font-size: 18px;
    line-height: 36px;
  }

  .gradResearchNoteContainer,
  .undergradResearchNoteContainer,
  .lorRequestNoteContainer {
    margin-bottom: 30px;
  }

  .gradResearchNoteContainer p,
  .undergradResearchNoteContainer p,
  .lorRequestNoteContainer p {
    font-size: 20px;
    line-height: 36px;
  }

  .contactFormSubmitButton {
    width: 166.65px;
    height: 50px;
  }

  .contactFormSubmitButton p {
    font-size: 18px;
  }

  .zoomMeetingDiv {
    padding: 20px;
    width: 548px;
    height: 350px;
  }

  .zoomMeetingDiv .message {
    font-size: 21px;
    line-height: 34px;
  }

  .zoomMeetingDiv img {
    margin-top: 10px;
    height: 100px;
  }

  .calendlyModalTriggerButton {
    bottom: 15px;
    width: 298px;
    height: 65px;
  }

  .calendlyModalTriggerButton p {
    font-size: 20px;
  }
}

@media screen and (min-width: 1300px) {
  .contactComponent {
    padding-top: 100px;
    min-height: 75vh;
    padding-bottom: 450px;
  }
  .contactComponent h1 {
    font-size: 50px;
    line-height: 70px;
  }

  .contactComponent h2 {
    font-size: 35px;
    line-height: 50px;
  }

  .contactForm {
    padding-top: 3vh;

    width: 1193px;
  }

  .doubleInputRow {
    grid-template-columns: 573.7px 573.7px;
    grid-column-gap: 45.6px;
    margin-bottom: 20px;
  }
  .singleInputRow {
    margin-bottom: 20px;
  }

  .contactForm label {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  .contactForm label input,
  .contactForm label select {
    height: 90px;
    margin-top: 5px;
    font-size: 18px;
    line-height: 36px;
  }

  .contactForm label input {
    width: 573.7px;
  }

  .contactForm label select {
    width: 1193px;
  }

  .contactForm label textarea {
    margin-top: 5px;
    width: 1193px;
    height: 297px;
    padding: 12px 20px;
    margin: 8px 0;
    font-size: 18px;
    line-height: 36px;
  }

  .contactForm label input::placeholder,
  .contactForm label select::placeholder,
  .contactForm label textarea::placeholder {
    font-size: 18px;
    line-height: 36px;
  }

  .gradResearchNoteContainer,
  .undergradResearchNoteContainer,
  .lorRequestNoteContainer {
    margin-bottom: 30px;
  }

  .gradResearchNoteContainer p,
  .undergradResearchNoteContainer p,
  .lorRequestNoteContainer p {
    font-size: 20px;
    line-height: 36px;
  }

  .contactFormSubmitButton {
    width: 217.75px;
    height: 65.33px;
  }

  .contactFormSubmitButton p {
    font-size: 20px;
  }

  .zoomMeetingDiv {
    padding: 20px;

    width: 548px;
    height: 350px;
  }

  .zoomMeetingDiv .message {
    font-size: 21px;
    line-height: 34px;
  }

  .zoomMeetingDiv img {
    margin-top: 10px;
    height: 100px;
  }

  .calendlyModalTriggerButton {
    bottom: 15px;
    width: 298px;
    height: 65px;
  }

  .calendlyModalTriggerButton p {
    font-size: 20px;
  }
}
