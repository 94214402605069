@media screen and (max-width: 480px) {
  .navContainer {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    height: 80px;
    z-index: 10;
    background-color: #070a20;
  }

  .navbar-desktop {
    display: none;
  }

  .navbar-mobile {
    top: 10px;
    left: 2.5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 95%;
  }

  .navbar-mobile-expanded {
    max-height: 95vh;
    overflow: scroll;
    border-radius: 16px;
    background: rgba(39, 43, 61, 0.99);
    padding-bottom: 30px;
  }

  .navbar-mobile .default {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .navbar-mobile .default .labLogoContainer {
    margin-left: 15px;
    display: flex;
    align-items: center;
  }

  .navbar-mobile .default .labLogoContainer .chatIcon {
    margin-top: 3px;
    height: 35px;
    width: auto;
  }

  .navbar-mobile .default .labLogoContainer .labLogoTextContainer {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }

  .navbar-mobile
    .default
    .labLogoContainer
    .labLogoTextContainer
    .labLogoText-Header {
    margin: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    text-align: center;
    font-size: 16px;
  }

  .navbar-mobile
    .default
    .labLogoContainer
    .labLogoTextContainer
    .labLogoText-Subeader {
    margin: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    text-align: center;
    font-size: 10px;
  }

  .navbar-mobile .dropdownButton {
    margin: 0;
    margin-right: 10px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-self: center;
    background: rgba(255, 255, 255, 0.32);
    border: none;
    border-radius: 50%;
    width: 45px;
    height: 45px;
  }

  .navbar-mobile .dropdownButton .menuIcon {
    margin: 0 auto;
  }

  .navbar-mobile .dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navbar-mobile .dropdown .navLinksContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
  }

  .navbar-mobile .dropdown .navLinksContainer .navLink {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 200%;
    color: #a2afc0;
    text-transform: capitalize;
  }

  .navbar-mobile .dropdown .socialIconsContainer {
    margin-top: 40px;
    margin-bottom: 70px;
    width: 100px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1023px) {
  .navContainer {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    height: 80px;
    z-index: 10;
    background-color: #070a20;
  }

  .navbar-desktop {
    display: none;
  }

  .navbar-mobile {
    top: 10px;
    left: 2.5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 95%;
  }

  .navbar-mobile-expanded {
    padding-bottom: 20px;
    border-radius: 16px;
    background: rgba(39, 43, 61, 0.99);
  }

  .navbar-mobile .default {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .navbar-mobile .default .labLogoContainer {
    margin-left: 10px;
    display: flex;
    align-items: center;
  }

  .navbar-mobile .default .labLogoContainer .chatIcon {
    margin-top: 5px;
    height: 35px;
    width: auto;
  }

  .navbar-mobile .default .labLogoContainer .labLogoTextContainer {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }

  .navbar-mobile
    .default
    .labLogoContainer
    .labLogoTextContainer
    .labLogoText-Header {
    margin: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    text-align: center;
    font-size: 16px;
  }

  .navbar-mobile
    .default
    .labLogoContainer
    .labLogoTextContainer
    .labLogoText-Subeader {
    margin: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    text-align: center;
    font-size: 10px;
  }

  .navbar-mobile .dropdownButton {
    margin: 0;
    margin-right: 10px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-self: center;
    background: rgba(255, 255, 255, 0.32);
    border: none;
    border-radius: 50%;
    width: 41px;
    height: 41px;
  }

  .navbar-mobile .dropdownButton .menuIcon {
    margin: 0 auto;
  }

  .navbar-mobile .dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navbar-mobile .dropdown .navLinksContainer {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0px 0px;
    box-sizing: border-box;
    max-width: 700px;
  }

  .navbar-mobile .dropdown .navLinksContainer .navLink {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 200%;
    margin: 0 12px;
    color: #a2afc0;
    text-transform: capitalize;
  }

  .navbar-mobile .dropdown .socialIconsContainer {
    margin-top: 40px;
    margin-bottom: 50px;
    width: 100px;
  }
}
