.sectionTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(63, 144, 206, 0.1);
  border-radius: 21px;
}

.sectionTitle p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  margin: 0;
  text-align: center;
  color: #5389ed;
}

@media screen and (max-width: 480px) {
  .sectionTitle {
    width: 140px;
    height: 38px;
  }

  .sectionTitle p {
    font-size: 14px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1023px) {
  .sectionTitle {
    width: 180px;
    height: 38px;
  }

  .sectionTitle p {
    font-size: 16px;
  }
}

@media screen and (min-width: 1024px) {
  .sectionTitle {
    width: 221px;
    height: 43px;
  }

  .sectionTitle p {
    font-size: 20px;
  }
}
