.researchGraphic {
  height: auto;
}

@media screen and (max-width: 480px) {
  .researchGraphic {
    margin: 50px 0 10px;
    width: 95vw;
  }
}

@media screen and (min-width: 481px) and (max-width: 1023px) {
  .researchGraphic {
    margin: 50px 0 10px;
    width: 60vw;
    max-width: 700px;
  }
}

@media screen and (min-width: 1024px) {
  .researchGraphic {
    margin: 100px 0 40px;
    width: 700px;
  }
}
