.publication {
  display: grid;
  margin-bottom: 30px;
  border-radius: 10px;
  background: rgba(41, 46, 71, 0.3);
  transition-duration: 0.75s;
}

.publication .numberContainer,
.publication .textContainer,
.publication .buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.publication .numberBox {
  background: rgba(63, 144, 206, 0.1);
  border-radius: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.publication .numberContainer p {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #5389ed;
}

.publication .textContainer {
  padding: 0 10px;
}

.publication .textContainer p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #a2afc0;
  margin: 0;
}

.publication .buttonContainer .linkButton {
  background: rgba(65, 72, 108, 0.51);
  border-radius: 50px 0px 40px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.75s;
}

.publication .buttonContainer .linkButton p {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
  transition-duration: 0.75s;
}

@media screen and (max-width: 480px) {
  .publication {
    margin-bottom: 15px;
    padding: 10px 0;
    width: 95vw;
    grid-template-columns: 55px 62vw 65px;
    justify-content: center;
  }

  .publication .numberContainer,
  .publication .textContainer,
  .publication .buttonContainer {
    max-height: 210px;
  }

  .publication .numberBox {
    width: 45px;
    height: 45px;
    border-radius: 10px;
  }

  .publication .numberContainer p {
    font-size: 18px;
  }

  .publication .textContainer p {
    font-size: 12px;
    line-height: 150%;
  }

  .publication .buttonContainer .linkButton {
    width: 60px;
    height: 30px;
  }

  .publication .buttonContainer .linkButton p {
    font-size: 12px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1024px) {
  .publication {
    width: 90vw;
    max-width: 800px;
    padding: 20px 0;
    grid-template-columns: 80px 100fr 90px;
  }

  .publication .numberContainer,
  .publication .textContainer,
  .publication .buttonContainer {
    max-height: 50vh;
  }

  .publication .numberBox {
    margin: 0;
    width: 50px;
    height: 50px;
    border-radius: 10px;
  }

  .publication .numberContainer p {
    font-size: 18px;
  }

  .publication .textContainer p {
    font-size: 15px;
    line-height: 150%;
  }

  .publication .buttonContainer .linkButton {
    width: 72px;
    height: 35px;
  }

  .publication .buttonContainer .linkButton p {
    font-size: 15px;
  }
}

@media screen and (min-width: 1024px) {
  .publication {
    padding: 20px 0;
  }

  .publication:hover {
    background: #292e47;
  }

  .publication .buttonContainer .linkButton:hover {
    text-decoration: none;
    background: #41486c;
  }

  .publication .buttonContainer .linkButton:hover p {
    color: #5389ed;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1324px) {
  .publication {
    width: 1000px;
    grid-template-columns: 150px 650px 200px;
  }

  .publication .numberBox {
    width: 70px;
    height: 64px;
  }

  .publication .numberContainer p {
    font-size: 35px;
  }

  .publication .textContainer p {
    font-size: 16px;
    line-height: 150%;
  }

  .publication .buttonContainer .linkButton {
    width: 125px;
    height: 64px;
  }

  .publication .buttonContainer .linkButton p {
    font-size: 20px;
  }
}

@media screen and (min-width: 1325px) and (max-width: 1599px) {
  .publication {
    width: 1250px;
    grid-template-columns: 200px 800px 250px;
  }

  .publication .numberBox {
    width: 80px;
    height: 69px;
  }

  .publication .numberContainer p {
    font-size: 38px;
  }

  .publication .textContainer p {
    font-size: 17px;
    line-height: 150%;
  }

  .publication .buttonContainer .linkButton {
    width: 140px;
    height: 69px;
  }

  .publication .buttonContainer .linkButton p {
    font-size: 22px;
  }
}

@media screen and (min-width: 1600px) {
  .publication {
    width: 1450px;
    grid-template-columns: 200px 1000px 250px;
  }

  .publication .numberBox {
    width: 90px;
    height: 74px;
  }

  .publication .numberContainer p {
    font-size: 40px;
  }

  .publication .textContainer p {
    font-size: 18px;
    line-height: 150%;
  }

  .publication .buttonContainer .linkButton {
    width: 155px;
    height: 74px;
  }

  .publication .buttonContainer .linkButton p {
    font-size: 24px;
  }
}
