@media screen and (max-width: 699px) {
  .hopferPhotoContainer,
  .photoContainer-Carousel,
  .photoContainer {
    display: none;
  }

  .mobilePhotoCarousel {
    display: block;
    overflow: hidden;
    border-radius: 20px;
  }

  .mobilePhotoCarousel .carousel-wrapper img {
    object-fit: cover;
    overflow: hidden;
    border-radius: 20px;
  }
}

@media screen and (max-width: 480px) {
  .mobilePhotoCarousel,
  .mobilePhotoCarousel .carousel-wrapper img {
    width: 85vw;
    height: 50vh;
  }
}

@media screen and (min-width: 481px) and (max-width: 699px) {
  .mobilePhotoCarousel,
  .mobilePhotoCarousel .carousel-wrapper img {
    max-width: 500px;
    width: 70vw;
    height: 300px;
  }
}
