.GifBox {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px solid rgba(63, 71, 107, 0.17);
  border-radius: 31px;
  margin: 20px 20px;
}

.GifBox .GIF {
  align-self: center;
  border-radius: 31px;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.GifBox .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
}

@media screen and (max-width: 699px) {
  .GifBox {
    width: 90vw;
    height: 350px;
    padding: 20px;
    margin: 0px 0px 20px 0px;
  }

  .GifBox .GIF {
    width: 80vw;
    height: 208px;
  }

  .GifBox .title {
    margin-top: 20px;
    font-size: 18px;
  }
}

@media screen and (min-width: 700px) and (max-width: 1023px) {
  .GifBox {
    width: 300px;
    height: 375px;
    padding: 30px;
  }

  .GifBox .GIF {
    width: 260px;
    height: 325px;
  }

  .GifBox .title {
    margin-top: 20px;
    font-size: 18px;
  }
}

@media screen and (min-width: 1024px) {
  .GifBox {
    width: 459px;
    height: 489px;
    padding: 30px;
    margin: 20px 40px;
  }

  .GifBox .GIF {
    width: 396px;
    height: 283px;
  }

  .GifBox .title {
    margin-top: 40px;
    font-size: 25px;
  }
}
