@media screen and (max-width: 800px) {
  footer {
    flex-direction: column;
  }

  .footerLeftContainer {
    display: none;
  }

  .footerLinksContainer .footerLink {
    margin: 0;
  }

  .footerContactContainer .textContainer {
    flex-direction: column;
  }

  .footerContactContainer .textContainer .address p {
    margin: 0;
  }

  .electronicCommunication {
    margin-top: 20px;
    flex-direction: column;
  }

  .footerContactMethod p {
    margin: 0;
    margin-left: 5px;
  }

  .socialContainer {
    margin-top: 20px;
  }

  .socialContainer .subheader {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 480px) {
  footer {
    padding: 10px 30px 20px;
  }

  .footerLinksContainer .subheader,
  .footerContactContainer .subheader,
  .socialContainer .subheader {
    font-size: 18px;
    line-height: 30px;
  }

  .footerLinksContainer .footerLink {
    font-size: 16px;
    line-height: 30px;
  }

  .footerContactContainer .textContainer .address p {
    font-size: 16px;
    line-height: 20px;
  }

  .footerContactMethod p {
    font-size: 16px;
    line-height: 30px;
  }
}

@media screen and (min-width: 481px) and (max-width: 650px) {
  footer {
    padding: 10px 40px 30px;
  }

  .footerLinksContainer .subheader,
  .footerContactContainer .subheader,
  .socialContainer .subheader {
    font-size: 20px;
    line-height: 35px;
  }

  .footerLinksContainer .footerLink {
    font-size: 18px;
    line-height: 30px;
  }
  .footerContactContainer .textContainer .address p {
    font-size: 16px;
    line-height: 22px;
  }

  .footerContactMethod p {
    font-size: 16px;
    line-height: 30px;
  }
}

@media screen and (min-width: 651px) and (max-width: 800px) {
  footer {
    padding: 10px 40px 30px;
  }

  .footerLinksContainer .subheader,
  .footerContactContainer .subheader,
  .socialContainer .subheader {
    font-size: 20px;
    line-height: 35px;
  }

  .footerLinksContainer .footerLink {
    font-size: 18px;
    line-height: 30px;
  }

  .footerContactContainer .textContainer .address p {
    font-size: 18px;
    line-height: 22px;
  }

  .footerContactMethod p {
    font-size: 18px;
    line-height: 30px;
  }
}
