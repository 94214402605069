.teachingComponent {
  margin: 0 auto;
  background-color: #070a20;
}

.teachingComponent h1,
.teachingComponent h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}

.teachingComponent h2 {
  text-align: left;
}

.coursesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 749px) {
  .teachingComponent {
    margin-top: 100px;
    width: 95vw;
    padding: 2vh 2vw;
    padding-bottom: 100px;
  }

  .teachingComponent h1 {
    font-size: 22px;
    line-height: 32px;
  }

  .teachingComponent h2 {
    font-size: 18px;
    line-height: 32px;
  }

  .coursesContainer {
    padding-top: 10px;
  }

  .gradCoursesContainer {
    margin-top: 20px;
  }
}

@media screen and (min-width: 750px) and (max-width: 1023px) {
  .teachingComponent {
    margin-top: 100px;
    width: 70%;
    min-width: 700px;
    padding: 2vh 2vw;
    padding-bottom: 100px;
  }

  .teachingComponent h1 {
    font-size: 40px;
    line-height: 60px;
  }

  .teachingComponent h2 {
    font-size: 25px;
    line-height: 50px;
  }

  .coursesContainer {
    padding-top: 10px;
  }

  .gradCoursesContainer {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1024px) {
  .teachingComponent {
    margin-top: 100px;
    width: 70%;
    min-width: 1000px;
    padding: 2vh 2vw;
    padding-bottom: 100px;
  }

  .teachingComponent h1 {
    font-size: 50px;
    line-height: 70px;
  }

  .teachingComponent h2 {
    font-size: 30px;
    line-height: 70px;
  }

  .coursesContainer {
    padding-top: 10px;
  }

  .gradCoursesContainer {
    margin-top: 40px;
  }
}
