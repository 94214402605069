.PeopleComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PeopleComponent h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
}

@media screen and (max-width: 574px) {
  .PeopleComponent {
    width: 100vw;
    padding-top: 100px;
    padding-bottom: 200px;
  }

  .PeopleComponent h1 {
    font-size: 22px;
    line-height: 32px;
  }

  .profileContainer,
  .alumniProfileContainer,
  .collaboratorProfileContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .PeopleComponent .alumniSectionHeading,
  .PeopleComponent .collaboratorSectionHeading {
    margin-top: 50px;
  }

  .profileContainer,
  .alumniProfileContainer,
  .collaboratorProfileContainer {
    width: 100%;
  }
}

@media screen and (min-width: 575px) and (max-width: 799px) {
  .PeopleComponent {
    width: 100vw;
    padding-top: 75px;
    padding-bottom: 100px;
  }

  .PeopleComponent h1 {
    font-size: 40px;
    line-height: 60px;
  }

  .profileContainer,
  .alumniProfileContainer,
  .collaboratorProfileContainer {
    margin-top: 30px;
    display: grid;
    grid-row-gap: 30px;
    justify-items: center;
    grid-template-columns: 50fr 50fr;
    width: 80vw;
  }

  .PeopleComponent .alumniSectionHeading,
  .PeopleComponent .collaboratorSectionHeading {
    margin-top: 50px;
  }
}

@media screen and (min-width: 800px) and (max-width: 1023px) {
  .PeopleComponent {
    width: 100vw;
    padding-top: 75px;
    padding-bottom: 100px;
  }

  .PeopleComponent h1 {
    font-size: 40px;
    line-height: 60px;
  }

  .profileContainer,
  .alumniProfileContainer,
  .collaboratorProfileContainer {
    margin-top: 30px;
    display: grid;
    grid-row-gap: 30px;
    justify-items: center;
    grid-template-columns: 50fr 50fr;
    width: 700px;
  }

  .PeopleComponent .alumniSectionHeading,
  .PeopleComponent .collaboratorSectionHeading {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1024px) {
  .PeopleComponent {
    width: 100vw;
    padding-top: 125px;
    padding-bottom: 200px;
  }

  .PeopleComponent h1 {
    font-size: 50px;
    line-height: 70px;
  }

  .profileContainer,
  .alumniProfileContainer,
  .collaboratorProfileContainer {
    margin-top: 50px;
    display: grid;
    grid-row-gap: 50px;
    justify-items: center;
    grid-template-columns: 33fr 33fr 33fr;
  }

  .PeopleComponent .alumniSectionHeading,
  .PeopleComponent .collaboratorSectionHeading {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1129px) {
  .profileContainer,
  .alumniProfileContainer,
  .collaboratorProfileContainer {
    width: 900px;
  }
}

@media screen and (min-width: 1130px) and (max-width: 1349px) {
  .profileContainer,
  .alumniProfileContainer,
  .collaboratorProfileContainer {
    width: 1100px;
  }
}

@media screen and (min-width: 1350px) {
  .profileContainer,
  .alumniProfileContainer,
  .collaboratorProfileContainer {
    width: 1300px;
  }
}
