.sloganComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sloganTextContainer,
.sloganSubheaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sloganTextContainer p {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  text-align: center;
  background: linear-gradient(270.12deg, #ffffff 2.69%, #5389ed 88.34%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.sloganSubheaderContainer p {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #a2afc0;
}

.mobileSloganTextContainer {
  display: none;
}

@media screen and (max-width: 480px) {
  .desktopSloganTextContainer {
    display: none;
  }

  .mobileSloganTextContainer {
    display: block;
  }

  .sloganComponent {
    margin-top: 40px;
  }

  .sloganTextContainer p {
    font-size: 22px;
  }

  .sloganSubheaderContainer {
    margin-top: 20px;
  }

  .sloganSubheaderContainer p {
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (min-width: 481px) and (max-width: 629px) {
  .desktopSloganTextContainer {
    display: none;
  }

  .mobileSloganTextContainer {
    display: block;
  }

  .sloganComponent {
    margin-top: 60px;
  }

  .sloganTextContainer p {
    font-size: 30px;
  }

  .sloganSubheaderContainer {
    margin-top: 20px;
  }

  .sloganSubheaderContainer p {
    font-size: 14px;
    line-height: 28px;
  }
}

@media screen and (min-width: 630px) and (max-width: 1023px) {
  .sloganComponent {
    margin-top: 60px;
  }

  .sloganTextContainer p {
    font-size: 22px;
  }

  .sloganSubheaderContainer {
    margin-top: 20px;
  }

  .sloganSubheaderContainer p {
    font-size: 16px;
    line-height: 30px;
  }
}

@media screen and (min-width: 700px) and (max-width: 774px) {
  .sloganTextContainer p {
    font-size: 26px;
  }
}

@media screen and (min-width: 775px) and (max-width: 825px) {
  .sloganTextContainer p {
    font-size: 28px;
  }
}

@media screen and (min-width: 826px) and (max-width: 899px) {
  .sloganTextContainer p {
    font-size: 30px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1023px) {
  .sloganTextContainer p {
    font-size: 32px;
  }
}

@media screen and (min-width: 1024px) {
  .sloganComponent {
    margin-top: 80px;
  }

  .sloganTextContainer p {
    font-size: 40px;
  }

  .sloganSubheaderContainer {
    margin-top: 30px;
  }

  .sloganSubheaderContainer p {
    font-size: 18px;
    line-height: 27px;
  }
}

@media screen and (min-width: 1310px) {
  .sloganTextContainer p {
    font-size: 50px;
  }
}
