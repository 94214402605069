.carousel-wrapper-research {
  width: 100%;
}

.carousel-wrapper-research img {
  width: auto;
  object-fit: contain;
  z-index: 9;
}

@media screen and (max-width: 480px) {
  .carousel-wrapper-research {
    height: 216px;
  }

  .carousel-wrapper-research img {
    max-width: 285px;
    max-height: 216px;
  }
}

@media screen and (min-width: 481px) and (max-width: 900px) {
  .carousel-wrapper-research {
    height: 300px;
    overflow: hidden;
  }

  .carousel-wrapper-research img {
    max-height: 300px;
    max-width: 80vw;
  }
}

@media screen and (min-width: 901px) and (max-width: 1023px) {
  .carousel-wrapper-research {
    height: 300px;
    overflow: hidden;
  }

  .carousel-wrapper-research img {
    max-height: 300px;
    max-width: 80vw;
  }
}

@media screen and (min-width: 1024px) {
  .carousel-wrapper-research img {
    max-height: 400px;
  }

  .arrowButton {
    color: white;
    z-index: 10;
    position: absolute;
    top: 50%;
    background: #5389ed;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: 0.5s;
  }

  .arrowButton:hover {
    background-color: #2167e9;
  }

  .arrowButtonLeft {
    left: 9%;
    transform: translate(-50%, -50%);
  }

  .arrowButtonRight {
    right: 4.25%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (min-width: 1024px) and (max-width: 1299px) {
  .carousel-wrapper-research {
    height: 400px;
  }

  .carousel-wrapper-research img {
    max-width: 600px;
  }

  .arrowButton {
    width: 51px;
    height: 51px;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1599px) {
  .carousel-wrapper-research {
    height: 400px;
  }

  .carousel-wrapper-research img {
    max-width: 700px;
  }

  .arrowButton {
    width: 51px;
    height: 51px;
  }
}

@media screen and (min-width: 1600px) {
  .carousel-wrapper-research {
    height: 400px;
  }

  .carousel-wrapper-research img {
    max-width: 750px;
  }

  .arrowButton {
    width: 51px;
    height: 51px;
  }
}
