.missionComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(41, 46, 71, 0.4);
  border-radius: 20px;
}

.missionComponent h2 {
  margin: 0;
  margin-top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}

.missionStatement {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #a2afc0;
  line-height: 150%;
}

@media screen and (max-width: 480px) {
  .missionComponent {
    margin-top: 10px;
    width: 95vw;
    padding: 20px 0px;
  }

  .missionComponent h2 {
    font-size: 21px;
    line-height: 32px;
  }

  .missionStatement {
    width: 100%;
    padding: 0 15px;
    margin-top: 20px;
    font-size: 12px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1023px) {
  .missionComponent {
    margin-top: 40px;
    width: 80vw;
    padding: 40px 40px;
  }

  .missionComponent h2 {
    font-size: 30px;
    line-height: 45px;
  }

  .missionStatement {
    margin-top: 25px;
    font-size: 15px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1299px) {
  .missionComponent {
    margin-top: 60px;
    width: 80vw;
    min-width: 800px;
    padding: 60px 60px;
  }
  .missionComponent h2 {
    font-size: 40px;
    line-height: 60px;
  }

  .missionStatement {
    margin-top: 30px;
    font-size: 18px;
  }
}

@media screen and (min-width: 1300px) {
  .missionComponent {
    margin-top: 100px;
    width: 1169px;
    height: 435px;
    padding: 60px 60px;
  }
  .missionComponent h2 {
    font-size: 50px;
    line-height: 70px;
  }

  .missionStatement {
    margin-top: 30px;
    font-size: 18px;
  }
}
