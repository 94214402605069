.researchComponent {
  padding-top: 20px;
  padding-bottom: 50px;
}

.researchComponent h1 {
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
}

.researchArea {
  box-sizing: border-box;
  border: 1px solid #ffffff;
  border-radius: 30px;
  background: rgba(39, 43, 61, 0.4);
}

.researchAreaTrigger {
  display: grid;
  transition-duration: 1s;
  cursor: pointer;
}

.researchAreaTrigger p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.01em;
  color: #ffffff;
}

.researchArea button {
  justify-self: end;
  margin-top: auto;
  margin-bottom: auto;
  background: rgba(255, 255, 255, 0.23);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}

.researchArea button p {
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  color: #ffffff;
}

.researchAreaContent {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

.researchAreaContent p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #a2afc0;
}

.projectDescription {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.projectDescription p {
  text-align: left;
}

.projectText {
  margin-bottom: 10px;
}

.projectLinks {
  margin-top: 20px;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.projectPublicationsContainer {
  margin-top: 20px;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.projectImages,
.projectVideo {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 801px) and (max-width: 1023px) {
  .researchComponent {
    margin: 100px auto 2vh;
    width: 90%;
    min-width: 600px;
    max-width: 800px;
    min-height: 90vh;
  }

  .researchComponent h1 {
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 50px;
  }

  .researchArea {
    margin-bottom: 20px;
  }

  .researchAreaTrigger {
    grid-template-columns: 90fr 10fr;
    padding: 40px 50px;
  }

  .researchAreaTrigger p {
    font-size: 18px;
  }

  .researchArea button {
    width: 45px;
    height: 45px;
  }

  .researchArea button p {
    font-size: 28px;
  }

  .researchAreaContent {
    padding: 0px 40px 50px;
  }

  .researchAreaContent p {
    font-size: 18px;
    line-height: 26px;
  }

  .projectImages,
  .projectVideo {
    margin-top: 40px;
  }

  .studyImage {
    height: 300px;
    width: 400px;
    object-fit: contain;
    margin: 0 auto;
  }

  .react-player {
    height: 300px;
    width: 400px;
  }
}

@media screen and (min-width: 1024px) {
  .researchComponent {
    margin: 100px auto 2vh;
    width: 90%;
    min-width: 950px;
    max-width: 1500px;
    min-height: 90vh;
  }

  .researchComponent h1 {
    font-size: 50px;
    line-height: 70px;
    margin-bottom: 50px;
  }

  .researchArea {
    margin-bottom: 30px;
  }

  .researchAreaTrigger {
    grid-template-columns: 90fr 10fr;
    padding: 40px 50px;
  }

  .researchAreaTrigger p {
    font-size: 22px;
  }

  .researchArea button {
    width: 51px;
    height: 51px;
  }

  .researchArea button p {
    font-size: 33px;
  }
  .researchAreaContent {
    padding: 0px 40px 50px;
  }

  .researchAreaContent p {
    font-size: 20px;
    line-height: 30px;
  }

  .projectImages,
  .projectVideo {
    margin-top: 40px;
  }

  .studyImage {
    height: 400px;
    width: auto;
    margin: 2vh auto;
  }

  .react-player {
    height: 300px;
    width: 400px;
  }
}
