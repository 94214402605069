.navExternalLinkButton {
  background-color: #5389ed;
  text-transform: capitalize;
  transition-duration: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
}

.navExternalLinkButton:hover {
  text-decoration: none;
  background-color: #2167e9;
}

.navExternalLinkButton p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

#normalNavExternalLinkButton p {
  color: white;
}

@media screen and (max-width: 480px) {
  #normalNavExternalLinkButton {
    width: 154.58px;
    height: 38.81px;
  }

  #normalNavExternalLinkButton p {
    font-size: 12px;
  }
}

@media screen and (min-width: 480px) and (max-width: 1023px) {
  #normalNavExternalLinkButton {
    width: 199px;
    height: 50px;
  }
  #normalNavExternalLinkButton p {
    font-size: 16px;
  }
}

@media screen and (min-width: 1024px) {
  #normalNavExternalLinkButton {
    width: 239px;
    height: 60px;
  }
  #normalNavExternalLinkButton p {
    font-size: 20px;
  }
}
