.navHashLinkButton {
  background-color: #5389ed;
  color: white;
  text-transform: capitalize;
  transition-duration: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
}

.navHashLinkButton:hover {
  text-decoration: none;
  color: white;
  background-color: #2167e9;
}

.navHashLinkButton p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

#normalNavHashLinkButton {
  padding: 7px 30px;
}

#normalNavHashLinkButton p {
  font-size: 16px;
}

@media screen and (max-width: 480px) {
  #largeNavHashLinkButton {
    width: 25vw;
    height: 10vw;
  }

  #largeNavHashLinkButton p {
    font-size: 11px;
  }
}

@media screen and (min-width: 481px) and (max-width: 714px) {
  #largeNavHashLinkButton {
    width: 125px;
    height: 38px;
  }

  #largeNavHashLinkButton p {
    font-size: 12.5px;
  }
}

@media screen and (min-width: 715px) and (max-width: 1023px) {
  #largeNavHashLinkButton {
    width: 150px;
    height: 45px;
  }

  #largeNavHashLinkButton p {
    font-size: 15px;
  }
}

@media screen and (min-width: 1024px) {
  #largeNavHashLinkButton {
    width: 200px;
    height: 60px;
  }

  #largeNavHashLinkButton p {
    font-size: 20px;
  }
}
