.profileComponent {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(63, 71, 107, 0.5);
  border-radius: 31px;
  box-sizing: border-box;
}

.profileComponent .image {
  align-self: center;
  object-fit: cover;
  border-radius: 31px;
  box-sizing: border-box;
}

.profileComponent .name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
}

.profileComponent .position {
  margin-top: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #a2afc0;
}

.profileComponent .moreInfoButton {
  display: flex;
  color: #ffffff;
  cursor: pointer;
}

.profileComponent .moreInfoButton p {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
}

.profileComponent .moreInfoButton .arrowIcon {
  margin: 0;
}

@media screen and (max-width: 799px) {
  .profileComponent {
    padding: 12.5px;
    width: 85vw;
    margin-bottom: 15px;
  }

  .profileComponent .image {
    width: 75vw;
    height: 225px;
  }

  .profileComponent .name {
    margin-top: 25px;
    font-size: 16px;
  }

  .profileComponent .position {
    font-size: 13px;
  }

  .profileComponent .moreInfoButton {
    width: 100px;
  }

  .profileComponent .moreInfoButton p {
    font-size: 12px;
  }
}

@media screen and (min-width: 575px) and (max-width: 799px) {
  .profileComponent {
    padding: 12.5px;
    width: 35vw;
    margin-bottom: 20px;
  }

  .profileComponent .image {
    width: 31.5vw;
    height: 200px;
  }
}

@media screen and (min-width: 800px) and (max-width: 1023px) {
  .profileComponent {
    padding: 20px;
    width: 325px;
  }

  .profileComponent .image {
    width: 290px;
    height: 250px;
  }

  .profileComponent .name {
    margin-top: 25px;
    font-size: 16px;
  }

  .profileComponent .position {
    font-size: 13px;
  }

  .profileComponent .moreInfoButton {
    width: 100px;
  }
  .profileComponent .moreInfoButton p {
    font-size: 13px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1129px) {
  .profileComponent {
    padding: 15px;
    width: 275px;
  }

  .profileComponent .image {
    width: 250px;
    height: 250px;
  }

  .profileComponent .name {
    margin-top: 25px;
    font-size: 16px;
  }

  .profileComponent .position {
    font-size: 13px;
  }

  .profileComponent .moreInfoButton {
    width: 100px;
  }
  .profileComponent .moreInfoButton p {
    font-size: 13px;
  }
}

@media screen and (min-width: 1130px) and (max-width: 1349px) {
  .profileComponent {
    padding: 15px;
    width: 325px;
  }

  .profileComponent .image {
    width: 280px;
    height: 250px;
  }

  .profileComponent .name {
    margin-top: 25px;
    font-size: 19px;
  }

  .profileComponent .position {
    font-size: 16px;
  }

  .profileComponent .moreInfoButton {
    width: 100px;
  }
  .profileComponent .moreInfoButton p {
    font-size: 14px;
  }
}

@media screen and (min-width: 1350px) {
  .profileComponent {
    padding: 20px;
    width: 375px;
  }

  .profileComponent .image {
    width: 335px;
    height: 250px;
  }

  .profileComponent .name {
    margin-top: 25px;
    font-size: 22px;
  }

  .profileComponent .position {
    font-size: 18px;
  }

  .profileComponent .moreInfoButton {
    width: 100px;
  }
  .profileComponent .moreInfoButton p {
    font-size: 16px;
  }
}
