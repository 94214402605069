.articleComponent {
  display: grid;
  background: rgba(39, 43, 61, 0.4);
  border-radius: 28px;
  margin-bottom: 30px;
}

.articleComponent .contentContainer {
  grid-column: 1 / span 1;
  width: 100%;
  height: 100%;
  padding-right: 0px;
}

.articleComponent .contentContainer .bubbleContainer {
  display: flex;
  justify-content: space-between;
}

.articleComponent .contentContainer .bubbleContainer .bubble {
  background: #292e47;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.articleComponent .contentContainer .bubbleContainer .bubble p {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
}

.articleComponent .contentContainer .articleLink {
  margin: 0;
  transition-duration: 0.75s;
}

.articleComponent .contentContainer .articleLink h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
  transition-duration: 0.75s;
}

.articleComponent .contentContainer .intro {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;

  color: #a2afc0;
}

.articleComponent .imageContainer {
  grid-column: 2 / span 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.articleComponent .imageContainer .image {
  object-fit: cover;
  object-position: top;
  border-radius: 20px;
}

@media screen and (min-width: 1024px) {
  .articleComponent .contentContainer .articleLink:hover {
    text-decoration: none;
  }

  .articleComponent .contentContainer .articleLink:hover h3 {
    color: #2167e9;
  }
}

@media screen and (max-width: 480px) {
  .articleComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95vw;
    margin-bottom: 15px;
  }

  .articleComponent .contentContainer {
    padding: 20px;
  }

  .articleComponent .contentContainer .bubbleContainer {
    width: 295px;
    margin-bottom: 15px;
  }

  .articleComponent .contentContainer .bubbleContainer .bubble {
    height: 36px;
  }

  .articleComponent .contentContainer .bubbleContainer .sourceBubble {
    width: 134px;
  }

  .articleComponent .contentContainer .bubbleContainer .dateBubble {
    width: 153px;
  }

  .articleComponent .contentContainer .bubbleContainer .bubble p {
    font-size: 11px;
  }

  .articleComponent .contentContainer .articleLink h3 {
    font-size: 14px;
    line-height: 150%;
  }

  .articleComponent .contentContainer .intro {
    margin-top: 15px;
    font-size: 12px;
    line-height: 150%;
  }

  .articleComponent .imageContainer .image {
    width: 87vw;
    height: 250px;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 481px) and (max-width: 700px) {
  .articleComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85vw;
    max-width: 500px;
  }

  .articleComponent .contentContainer {
    padding: 20px;
  }

  .articleComponent .contentContainer .bubbleContainer {
    width: 295px;
    margin-bottom: 15px;
  }

  .articleComponent .contentContainer .bubbleContainer .bubble {
    height: 32px;
  }

  .articleComponent .contentContainer .bubbleContainer .sourceBubble {
    width: 134px;
  }

  .articleComponent .contentContainer .bubbleContainer .dateBubble {
    width: 153px;
  }

  .articleComponent .contentContainer .bubbleContainer .bubble p {
    font-size: 10px;
  }

  .articleComponent .contentContainer .articleLink h3 {
    font-size: 14px;
  }

  .articleComponent .contentContainer .intro {
    margin-top: 15px;
    font-size: 12px;
    line-height: 19px;
  }

  .articleComponent .imageContainer .image {
    width: 80vw;
    max-width: 460px;
    height: 300px;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 701px) and (max-width: 1023px) {
  .articleComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75vw;
    max-width: 650px;
  }

  .articleComponent .contentContainer {
    padding: 20px;
  }

  .articleComponent .contentContainer .bubbleContainer {
    width: 295px;
    margin-bottom: 15px;
  }

  .articleComponent .contentContainer .bubbleContainer .bubble {
    height: 32px;
  }

  .articleComponent .contentContainer .bubbleContainer .sourceBubble {
    width: 134px;
  }

  .articleComponent .contentContainer .bubbleContainer .dateBubble {
    width: 153px;
  }

  .articleComponent .contentContainer .bubbleContainer .bubble p {
    font-size: 10px;
  }

  .articleComponent .contentContainer .articleLink h3 {
    font-size: 16px;
  }

  .articleComponent .contentContainer .intro {
    margin-top: 15px;
    font-size: 14px;
    line-height: 22px;
  }

  .articleComponent .imageContainer .image {
    width: 70vw;
    max-width: 610px;
    height: 300px;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1154px) {
  .articleComponent {
    grid-template-columns: 580px 320px;
    width: 900px;
    height: 508px;
  }

  .articleComponent .contentContainer {
    padding: 30px;
  }

  .articleComponent .contentContainer .bubbleContainer {
    width: 530px;
    margin-bottom: 20px;
  }

  .articleComponent .contentContainer .bubbleContainer .bubble {
    height: 50px;
  }

  .articleComponent .contentContainer .bubbleContainer .sourceBubble {
    width: 230px;
  }

  .articleComponent .contentContainer .bubbleContainer .dateBubble {
    width: 262px;
  }

  .articleComponent .contentContainer .bubbleContainer .bubble p {
    font-size: 14px;
  }

  .articleComponent .contentContainer .articleLink h3 {
    font-size: 22px;
  }

  .articleComponent .contentContainer .intro {
    margin-top: 20px;
    font-size: 16px;
    line-height: 30px;
  }

  .articleComponent .imageContainer .image {
    width: 270px;
    height: 300px;
  }
}

@media screen and (min-width: 1155px) and (max-width: 1489px) {
  .articleComponent {
    grid-template-columns: 700px 400px;
    width: 1100px;
    height: 508px;
  }

  .articleComponent .contentContainer {
    padding: 30px;
  }

  .articleComponent .contentContainer .bubbleContainer {
    width: 550px;
    margin-bottom: 20px;
  }

  .articleComponent .contentContainer .bubbleContainer .bubble {
    height: 50px;
  }

  .articleComponent .contentContainer .bubbleContainer .sourceBubble {
    width: 240px;
  }

  .articleComponent .contentContainer .bubbleContainer .dateBubble {
    width: 272px;
  }

  .articleComponent .contentContainer .bubbleContainer .bubble p {
    font-size: 16px;
  }

  .articleComponent .contentContainer .articleLink h3 {
    font-size: 22px;
  }

  .articleComponent .contentContainer .intro {
    margin-top: 20px;
    font-size: 16px;
    line-height: 30px;
  }

  .articleComponent .imageContainer .image {
    width: 370px;
    height: 440px;
  }
}

@media screen and (min-width: 1490px) {
  .articleComponent {
    grid-template-columns: 866px 550px;
    width: 1416px;
    height: 508px;
  }

  .articleComponent .contentContainer {
    padding: 30px;
  }

  .articleComponent .contentContainer .bubbleContainer {
    width: 550px;
    margin-bottom: 30px;
  }

  .articleComponent .contentContainer .bubbleContainer .bubble {
    height: 58px;
  }

  .articleComponent .contentContainer .bubbleContainer .sourceBubble {
    width: 246px;
  }

  .articleComponent .contentContainer .bubbleContainer .dateBubble {
    width: 282px;
  }

  .articleComponent .contentContainer .bubbleContainer .bubble p {
    font-size: 18px;
  }

  .articleComponent .contentContainer .articleLink h3 {
    font-size: 25px;
  }

  .articleComponent .contentContainer .intro {
    margin-top: 30px;
    font-size: 18px;
    line-height: 36px;
  }

  .articleComponent .imageContainer .image {
    width: 520px;
    height: 476.49px;
  }
}
