@media screen and (min-width: 700px) {
  .photoBoxComponent {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin: 0 auto;
  }

  .photoContainer {
    overflow: hidden;
    border-radius: 20px;
  }

  .photoContainer img {
    object-fit: cover;
    overflow: hidden;
  }

  .photoContainer-Carousel {
    overflow: hidden;
    margin: 0 25px;
    border-radius: 20px;
  }

  .photoContainer-Carousel .carousel-wrapper img {
    object-fit: cover;
    overflow: hidden;
    border-radius: 20px;
  }

  .hopferPhotoContainer {
    position: relative;
    text-align: center;
    color: black;
  }

  .blurredImage {
    opacity: 0.25;
  }

  .hopferBio {
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    animation: fadeIn linear 1.5s;
  }

  .hopferBio p {
    text-align: left;
  }

  .mobilePhotoCarousel {
    display: none;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@media screen and (min-width: 700px) and (max-width: 1023px) {
  .photoContainer,
  .photoContainer img {
    width: 23vw;
    height: 225px;
  }

  .photoContainer-Carousel {
    margin: 0 10px;
  }

  .photoContainer-Carousel,
  .photoContainer-Carousel .carousel-wrapper img {
    width: 35vw;
    height: 225px;
  }

  .hopferBio {
    display: none;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1299px) {
  .photoContainer,
  .photoContainer img {
    width: 250px;
    height: 300px;
  }

  .photoContainer-Carousel,
  .photoContainer-Carousel .carousel-wrapper img {
    width: 350px;
    height: 300px;
  }

  .hopferBio {
    width: 95%;
    font-size: 11px;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1624px) {
  .photoContainer,
  .photoContainer img {
    width: 300px;
    height: 350px;
  }

  .photoContainer-Carousel,
  .photoContainer-Carousel .carousel-wrapper img {
    width: 500px;
    height: 350px;
  }

  .hopferBio {
    font-size: 12px;
  }
}

@media screen and (min-width: 1625px) {
  .photoContainer,
  .photoContainer img {
    width: 430px;
    height: 400px;
  }

  .photoContainer-Carousel,
  .photoContainer-Carousel .carousel-wrapper img {
    width: 600px;
    height: 400px;
  }

  .hopferBio {
    font-size: 14px;
  }
}
