@media screen and (max-width: 800px) {
  .researchComponent {
    margin: 75px auto;
    width: 95%;
  }

  .researchComponent h1 {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 30px;
  }

  .researchArea {
    margin-bottom: 15px;
  }

  .researchAreaTrigger {
    grid-template-columns: 85fr 15fr;
    padding: 15px 20px;
  }

  .researchAreaTrigger p {
    font-size: 16px;
  }

  .researchArea button {
    width: 36px;
    height: 36px;
  }

  .researchArea button p {
    font-size: 20px;
  }

  .researchAreaContent {
    padding: 0px 10px 30px;
  }

  .researchAreaContent p {
    font-size: 14px;
    line-height: 150%;
  }

  .projectImages,
  .projectVideo {
    margin-top: 10px;
  }

  .studyImage,
  .react-player {
    height: 216px;
    width: 285px;
    object-fit: contain;
    margin: 0 auto;
  }
}

@media screen and (min-width: 481px) and (max-width: 800px) {
  .researchComponent h1 {
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 30px;
  }

  .researchArea {
    margin-bottom: 40px;
  }

  .researchAreaTrigger {
    grid-template-columns: 85fr 15fr;
    padding: 30px 40px;
  }

  .researchAreaTrigger p {
    font-size: 15px;
  }

  .researchAreaContent {
    padding: 0px 30px 30px;
  }

  .researchAreaContent p {
    font-size: 14px;
    line-height: 20px;
  }

  .studyImage,
  .react-player {
    height: 300px;
    width: 400px;
    object-fit: contain;
    margin: 0 auto;
  }
}
