.button-container {
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

@media screen and (max-width: 480px) {
  .button-container {
    width: 85vw;
    margin-top: 0px;
  }
}

@media screen and (min-width: 481px) and (max-width: 714px) {
  .button-container {
    width: 425px;
  }
}

@media screen and (min-width: 715px) and (max-width: 1023px) {
  .button-container {
    width: 70vw;
    max-width: 500px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1024px) {
  .button-container {
    width: 700px;
  }
}
