/* universal rules */
nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #070a20;
}

.navbar-desktop {
  height: 80px;
}

.navbar-mobile {
  display: none;
}

.navLinksContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.socialIconsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.socialIcon {
  color: #dcddf0;
}

/* universal desktop rules */
@media screen and (min-width: 1024px) {
  .navLink:hover {
    text-decoration: none;
    color: #5389ed;
  }

  .socialIcon {
    transition-duration: 0.75s;
    margin-top: 3px;
  }

  .socialIcon:hover {
    color: #5389ed;
  }
}

/* adjusts navbar spacing on very small desktop screen */
@media screen and (min-width: 1024px) and (max-width: 1270px) {
  .labLogoContainer {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  .chatIcon {
    margin-top: 5px;
    height: 40px;
    width: auto;
  }

  .labLogoTextContainer {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }

  .labLogoText-Header {
    margin: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    text-align: center;
    font-size: 18px;
  }

  .labLogoText-Header-Span {
    display: none;
  }

  .labLogoText-Subeader {
    display: none;
  }

  .navLink {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    text-align: center;

    color: #a2afc0;
    text-transform: capitalize;
    transition: 0.5s;
    cursor: pointer;

    margin: 0 15px;
  }

  .socialIconsContainer {
    margin-left: 20px;
    margin-right: 25px;
    width: 70px;
  }
}

/* adjusts navbar spacing on small desktop screen */
@media screen and (min-width: 1271px) and (max-width: 1684px) {
  .labLogoContainer {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  .chatIcon {
    margin-top: 5px;
    height: 40px;
    width: auto;
  }

  .labLogoTextContainer {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }

  .labLogoText-Header {
    margin: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    text-align: center;
  }

  .labLogoText-Header {
    font-size: 18px;
  }

  .labLogoText-Subeader {
    margin: 0;
    font-size: 10px;
    word-spacing: 0.5px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
  }

  .navLink {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;

    color: #a2afc0;
    text-transform: capitalize;
    transition: 0.5s;
    cursor: pointer;

    margin: 0 15px;
  }

  .socialIconsContainer {
    margin-left: 20px;
    margin-right: 25px;
    width: 70px;
  }
}

/* adjusts navbar spacing on standard-large desktop screens */
@media screen and (min-width: 1685px) {
  .labLogoContainer {
    margin-right: 60px;
    display: flex;
    align-items: center;
  }

  .chatIcon {
    margin-top: 5px;
    height: 40px;
    width: auto;
  }

  .labLogoTextContainer {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }

  .labLogoText-Header,
  .labLogoText-Subeader {
    margin: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    text-align: center;
  }

  .labLogoText-Header {
    font-size: 20px;
    word-spacing: 2px;
    letter-spacing: 1px;
  }

  .labLogoText-Subeader {
    font-size: 10px;
    word-spacing: 0.5px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
  }

  .navLink {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;

    color: #a2afc0;
    text-transform: capitalize;
    transition: 0.5s;
    cursor: pointer;

    margin: 0 30px;
  }

  .socialIconsContainer {
    margin-left: 60px;
    margin-right: 80px;
    width: 80px;
  }
}
