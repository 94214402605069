.newsComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsComponent h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
}

.articleContainer {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 480px) {
  .newsComponent {
    padding-top: 90px;
    padding-bottom: 100px;
  }

  .newsComponent h1 {
    font-size: 22px;
    line-height: 32px;
  }

  .articleContainer {
    margin-top: 20px;
  }
}

@media screen and (min-width: 481px) and (max-width: 700px) {
  .newsComponent {
    padding-top: 100px;
    padding-bottom: 117px;
  }

  .newsComponent h1 {
    font-size: 30px;
    line-height: 42px;
  }

  .articleContainer {
    margin-top: 30px;
  }
}

@media screen and (min-width: 701px) and (max-width: 1023px) {
  .newsComponent {
    padding-top: 100px;
    padding-bottom: 133px;
  }

  .newsComponent h1 {
    font-size: 40px;
    line-height: 56px;
  }

  .articleContainer {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1024px) {
  .newsComponent {
    padding-top: 100px;
    padding-bottom: 150px;
  }

  .newsComponent h1 {
    font-size: 50px;
    line-height: 70px;
  }

  .articleContainer {
    margin-top: 50px;
  }
}
