footer {
  width: 100vw;
  left: 0;
  bottom: 0;
  background: #292e47;
  display: flex;
  padding: 30px 40px;
}

.footerLeftContainer {
  display: flex;
  flex-direction: column;
}

.footerLeftContainer p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  color: #ffffff;
}

.footerLeftContainer img {
  box-sizing: border-box;
  object-fit: cover;
  border: 3px solid #ffffff;
}

.footerLinksContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.footerLinksContainer .subheader,
.footerContactContainer .subheader {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;

  color: #ffffff;
}

.footerLinksContainer .footerLink {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  color: #dcddf0;
  opacity: 0.7;
  text-transform: capitalize;
  transition-duration: 0.75s;
}

.footerLinksContainer .footerLink:hover {
  text-decoration: none;
  color: #5389ed;
}

.footerContactContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.footerContactContainer .textContainer {
  display: flex;
}

.footerContactContainer .textContainer .address {
  margin-top: 8px;
}

.footerContactContainer .textContainer .address p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #dcddf0;
  opacity: 0.7;
}

.electronicCommunication {
  display: flex;
}

.footerContactMethod {
  display: flex;
}

.footerContactMethod:hover {
  text-decoration: none;
}

.footerContactMethod:hover .footerContactIcon,
.footerContactMethod:hover p {
  color: #5389ed;
}

.footerContactMethod .footerContactIcon {
  color: #dcddf0;
  margin-top: 5px;
}

.footerContactMethod p {
  margin-left: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #dcddf0;
  opacity: 0.7;
}

.socialContainer {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.socialContainer .subheader {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
}

.socialIconsContainer-footer {
  margin-top: 10px;
  display: flex;
  width: 70px;
  justify-content: space-between;
}

@media screen and (min-width: 801px) and (max-width: 1023px) {
  footer {
    padding: 20px 40px 60px;
  }

  .footerLeftContainer {
    display: none;
  }

  .footerLinksContainer {
    margin-left: 50px;
    width: 200px;
  }

  .footerLinksContainer .subheader,
  .footerContactContainer .subheader,
  .socialContainer .subheader {
    font-size: 22px;
    line-height: 30px;
  }

  .footerLinksContainer .footerLink {
    font-size: 18px;
    line-height: 35px;
  }

  .footerContactContainer {
    margin-left: 40px;
  }

  .footerContactContainer .textContainer {
    flex-direction: column;
  }

  .footerContactContainer .textContainer .address p {
    font-size: 16px;
    line-height: 26px;
    margin: 0;
  }

  .electronicCommunication {
    margin-top: 50px;
    align-items: center;
    justify-content: space-between;
  }

  .footerContactMethod .footerContactIcon {
    margin-top: 3px;
  }

  .footerContactMethod p {
    font-size: 18px;
  }

  .socialContainer {
    margin-top: 0px;
  }
}

@media screen and (min-width: 1024px) {
  .footerLinksContainer {
    width: 200px;
  }

  .footerLinksContainer .subheader,
  .footerContactContainer .subheader {
    font-size: 25px;
    line-height: 40px;
  }

  .footerContactMethod p {
    font-size: 21px;
  }

  .socialContainer .subheader {
    font-size: 25px;
  }

  .socialIconsContainer-footer a .socialIcon {
    color: #dcddf0;
  }

  .socialIconsContainer-footer a .socialIcon:hover {
    color: #5389ed;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1219px) {
  .footerLeftContainer {
    margin-left: 20px;
  }

  .footerLeftContainer p {
    font-size: 24px;
    line-height: 30px;
  }

  .footerLeftContainer img {
    width: 250px;
    height: 400px;
  }

  .footerLinksContainer {
    margin-left: 50px;
  }

  .footerLinksContainer .footerLink {
    font-size: 18px;
    line-height: 40px;
  }

  .footerContactContainer {
    margin-left: 75px;
  }

  .footerContactContainer .textContainer {
    flex-direction: column;
  }

  .footerContactContainer .textContainer .address p {
    font-size: 18px;
    line-height: 30px;
  }

  .electronicCommunication {
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
  }

  .footerContactMethod .footerContactIcon {
    margin-top: 3px;
  }

  .footerContactMethod p {
    font-size: 18px;
  }

  .socialContainer {
    margin-top: 0px;
  }

  .socialContainer .subheader {
    font-size: 22px;
  }
}

@media screen and (min-width: 1220px) and (max-width: 1539px) {
  .footerLeftContainer {
    margin-left: 20px;
  }

  .footerLeftContainer p {
    font-size: 25px;
    line-height: 40px;
  }

  .footerLeftContainer img {
    width: 250px;
    height: 400px;
  }

  .footerLinksContainer {
    margin-left: 50px;
  }

  .footerLinksContainer .footerLink {
    font-size: 21px;
    line-height: 50px;
  }

  .footerContactContainer {
    margin-left: 75px;
  }

  .footerContactContainer .textContainer {
    flex-direction: column;
  }

  .footerContactContainer .textContainer .address p {
    font-size: 21px;
    line-height: 30px;
  }

  .electronicCommunication {
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
  }

  .socialContainer {
    margin-top: 0px;
  }
}

@media screen and (min-width: 1540px) and (max-width: 1790px) {
  .footerLeftContainer {
    margin-left: 25px;
  }

  .footerLeftContainer p {
    font-size: 25px;
    line-height: 40px;
  }

  .footerLeftContainer img {
    width: 275px;
    height: 350px;
  }

  .footerLinksContainer {
    margin-left: 50px;
  }

  .footerLinksContainer .footerLink {
    font-size: 21px;
    line-height: 50px;
  }

  .footerContactContainer {
    margin-left: 75px;
  }

  .footerContactContainer .textContainer .address p {
    font-size: 21px;
    line-height: 30px;
  }

  .electronicCommunication {
    margin-left: 50px;
    flex-direction: column;
  }

  .socialContainer {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1791px) {
  .footerLeftContainer {
    margin-left: 75px;
  }

  .footerLeftContainer p {
    font-size: 25px;
    line-height: 40px;
  }

  .footerLeftContainer img {
    width: 302px;
    height: 419px;
  }

  .footerLinksContainer {
    margin-left: 100px;
  }

  .footerLinksContainer .footerLink {
    font-size: 21px;
    line-height: 50px;
  }

  .footerContactContainer {
    margin-left: 150px;
  }

  .footerContactContainer .textContainer .address p {
    font-size: 21px;
    line-height: 30px;
  }

  .electronicCommunication {
    margin-left: 80px;
    flex-direction: column;
  }

  .socialContainer {
    margin-top: 50px;
  }
}
