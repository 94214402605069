/* style ruleset for profile modal */

.profileModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(39, 43, 61, 0.4);
  border-radius: 30px;
  overflow: hidden;
}

.profileModal .closeButton {
  position: absolute;
  cursor: pointer;
  transition-duration: 0.75s;
  background: rgba(255, 255, 255, 0.23);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.profileModal .closeButton p {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  text-align: center;
  transition-duration: 0.75s;
  color: #ffffff;
}

.profileModal .gridContainer {
  max-height: 98vh;
  overflow: hidden;
}

.profileModal .gridContainer .leftGrid {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}

.profileModal .gridContainer .leftGrid .profileImage {
  border-radius: 31px;
  object-fit: cover;
  object-position: top;
}

.profileModal .gridContainer .leftGrid .emailButton {
  margin-top: 40px;
  margin-bottom: 40px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition-duration: 0.75s;
  width: 175px;
  height: 55px;
  background: #5389ed;
  border-radius: 200px;
}

.profileModal .gridContainer .leftGrid .emailButton:hover {
  text-decoration: none;
  background-color: #2167e9;
}

.profileModal .gridContainer .leftGrid .emailButton img {
  height: 30px;
  width: auto;
}

.profileModal .gridContainer .leftGrid .emailButton p {
  margin: 0;
  margin-left: 5px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;

  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}

.profileModal .gridContainer .rightGrid {
  grid-column: 2;
  padding-top: 0;
}

.profileModal .gridContainer .rightGrid .profileName {
  margin-top: 0;
  padding-top: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
}

.profileModal .gridContainer .rightGrid .position {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.81);
}

.profileModal .gridContainer .rightGrid .background,
.profileModal .gridContainer .rightGrid .research {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #a2afc0;
}

@media screen and (max-width: 375px) {
  .profileModal {
    max-height: 98vh;
  }

  .profileModal .gridContainer {
    max-height: 93.5vh;
    overflow: hidden;
  }
}

@media screen and (max-height: 375px) {
  .profileModal {
    max-height: 98vh;
  }

  .profileModal .gridContainer {
    max-height: 90vh;
    overflow: hidden;
  }
}

@media screen and (max-width: 799px) {
  .profileModal {
    width: 95%;
    padding: 20px 15px;
    overflow: hidden;
  }

  .profileModal .closeButton {
    top: 10px;
    right: 15px;
    width: 50px;
    height: 50px;
  }

  .profileModal .closeButton p {
    font-size: 30px;
  }

  .profileModal .gridContainer {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .profileModal .gridContainer .leftGrid {
    display: none;
  }

  .profileModal .gridContainer .rightGrid {
    overflow: hidden;
  }

  .profileModal .gridContainer .rightGrid .profileName {
    padding-top: 0px;
    margin: 0;
    font-size: 18px;
    line-height: 30px;
  }

  .profileModal .gridContainer .rightGrid .position {
    font-size: 13px;
    line-height: 20px;
  }

  .profileModal .gridContainer .rightGrid .background,
  .profileModal .gridContainer .rightGrid .research {
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (min-width: 800px) and (max-width: 1023px) {
  .profileModal {
    width: 95%;
    padding: 10px 15px;
    overflow: hidden;
  }

  .profileModal .closeButton {
    top: 10px;
    right: 15px;
    width: 50px;
    height: 50px;
  }

  .profileModal .closeButton p {
    font-size: 30px;
  }

  .profileModal .gridContainer {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 80vh;
  }

  .profileModal .gridContainer .leftGrid {
    display: none;
  }

  .profileModal .gridContainer .rightGrid {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .profileModal .gridContainer .rightGrid .profileName {
    padding-top: 0px;
    margin: 0;
    font-size: 18px;
    line-height: 30px;
  }

  .profileModal .gridContainer .rightGrid .position {
    font-size: 13px;
    line-height: 20px;
  }

  .profileModal .gridContainer .rightGrid .background,
  .profileModal .gridContainer .rightGrid .research {
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (min-width: 1024px) {
  .profileModal .closeButton {
    top: 20px;
    right: 25px;
    width: 50px;
    height: 50px;
  }

  .profileModal .closeButton:hover {
    background: white;
  }

  .profileModal .closeButton:hover p {
    color: rgb(45, 38, 38);
  }

  .profileModal .closeButton p {
    font-size: 30px;
  }

  .profileModal .gridContainer {
    display: grid;
    width: 100%;
    grid-template-columns: 40fr 60fr;
    padding: 20px 0;
  }

  .profileModal .gridContainer .rightGrid {
    max-height: 93vh;
    padding: 0 20px;
    overflow: hidden;
  }

  .profileModal .gridContainer .rightGrid .profileName {
    font-size: 25px;
    line-height: 70px;
  }

  .profileModal .gridContainer .rightGrid .position {
    font-size: 20px;
    line-height: 30px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1189px) {
  .profileModal {
    width: 900px;
  }

  .profileModal .gridContainer .leftGrid .profileImage {
    width: 300px;
    height: 375px;
  }

  .profileModal .gridContainer .rightGrid .profileName {
    padding-top: 15px;
    font-size: 25px;
    line-height: 60px;
  }

  .profileModal .gridContainer .rightGrid .position {
    font-size: 20px;
    line-height: 30px;
  }

  .profileModal .gridContainer .rightGrid .background,
  .profileModal .gridContainer .rightGrid .research {
    font-size: 16px;
    line-height: 22px;
  }
}

@media screen and (min-width: 1190px) and (max-width: 1479px) {
  .profileModal {
    width: 1100px;
  }

  .profileModal .gridContainer .leftGrid .profileImage {
    width: 350px;
    height: 375px;
  }

  .profileModal .gridContainer .rightGrid .background,
  .profileModal .gridContainer .rightGrid .research {
    font-size: 18px;
    line-height: 27px;
  }
}

@media screen and (min-width: 1480px) {
  .profileModal {
    width: 1400px;
  }

  .profileModal .gridContainer .leftGrid .profileImage {
    width: 400px;
    height: 425px;
  }

  .profileModal .gridContainer .rightGrid .background,
  .profileModal .gridContainer .rightGrid .research {
    font-size: 18px;
    line-height: 27px;
  }
}
