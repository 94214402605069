.aboutHopfer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 200px;
}

.aboutHopfer .headshotContainer img {
  object-fit: cover;
  border-radius: 20px;
}

.aboutHopfer .textContainer h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
}

.aboutHopfer .textContainer .biography p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #a2afc0;
}

.buttonContainer {
  margin-top: 40px;
}

@media screen and (max-width: 699px) {
  .aboutHopfer {
    margin-bottom: 50px;
  }

  .aboutHopfer .headshotContainer {
    display: none;
  }

  .aboutHopfer .textContainer {
    width: 85vw;
    padding: 0;
  }

  .aboutHopfer .textContainer h2 {
    margin-top: 10px;
    font-size: 22px;
    line-height: 32px;
  }

  .aboutHopfer .textContainer .biography {
    margin-top: 20px;
  }

  .aboutHopfer .textContainer .biography p {
    font-size: 12px;
    line-height: 150%;
  }
}

@media screen and (min-width: 700px) and (max-width: 1023px) {
  .aboutHopfer .headshotContainer {
    padding-top: 20px;
  }

  .aboutHopfer .headshotContainer img {
    width: 30vw;
    height: 350px;
  }

  .aboutHopfer .textContainer {
    margin-left: 30px;
    width: 60vw;
    padding: 0;
  }

  .aboutHopfer .textContainer h2 {
    margin-top: 10px;
    font-size: 30px;
    line-height: 45px;
  }

  .aboutHopfer .textContainer .biography {
    margin-top: 10px;
  }

  .aboutHopfer .textContainer .biography p {
    font-size: 13px;
    line-height: 18px;
  }

  .buttonContainer {
    margin-top: 10px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1303px) {
  .aboutHopfer .headshotContainer {
    padding-top: 20px;
  }

  .aboutHopfer .headshotContainer img {
    height: 500px;
    width: 350px;
  }

  .aboutHopfer .textContainer {
    margin-left: 30px;
    width: 550px;
    padding: 0;
  }

  .aboutHopfer .textContainer h2 {
    margin-top: 10px;
    font-size: 35px;
    line-height: 55px;
  }

  .aboutHopfer .textContainer .biography {
    margin-top: 15px;
  }

  .aboutHopfer .textContainer .biography p {
    font-size: 14px;
    line-height: 23px;
  }

  .buttonContainer {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1304px) and (max-width: 1641px) {
  .aboutHopfer .headshotContainer {
    padding-top: 20px;
  }

  .aboutHopfer .headshotContainer img {
    height: 500px;
    width: 400px;
  }

  .aboutHopfer .textContainer {
    margin-left: 40px;
    width: 700px;
    padding: 0;
  }

  .aboutHopfer .textContainer h2 {
    margin-top: 10px;
    font-size: 40px;
    line-height: 60px;
  }

  .aboutHopfer .textContainer .biography {
    margin-top: 20px;
  }

  .aboutHopfer .textContainer .biography p {
    font-size: 16px;
    line-height: 25px;
  }
}

@media screen and (min-width: 1642px) {
  .aboutHopfer .headshotContainer {
    padding-top: 40px;
  }

  .aboutHopfer .headshotContainer img {
    height: 700px;
    width: 600px;
  }

  .aboutHopfer .textContainer {
    margin-left: 40px;
    width: 900px;
    padding: 0;
  }

  .aboutHopfer .textContainer h2 {
    margin-top: 10px;
    font-size: 50px;
    line-height: 70px;
  }

  .aboutHopfer .textContainer .biography {
    margin-top: 30px;
  }

  .aboutHopfer .textContainer .biography p {
    font-size: 18px;
    line-height: 27px;
  }
}
