.projectPublication {
  background: #292e47;
  border-radius: 10px;
  display: grid;
  width: 100%;
}

.projectPublicationTextContainer {
  display: flex;
  width: 100%;
}

.projectPublication .projectPublicationTextContainer p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
  margin: 0;
  line-height: 150%;
}

.projectPublication a {
  justify-self: end;
  background: #41486c;
  border-radius: 50px 0px 40px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.75s;
  align-self: center;
}

.projectPublication a p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #5389ed;
  margin: 0;
  transition-duration: 0.75s;
}

@media screen and (max-width: 480px) {
  .projectPublication {
    margin-bottom: 20px;
    grid-template-columns: 100fr 60px;
    padding: 10px 15px;
  }

  .projectPublication .projectPublicationTextContainer p {
    font-size: 10px;
  }

  .projectPublication a {
    width: 48px;
    height: 23px;
  }

  .projectPublication a p {
    font-size: 10px;
    line-height: 15px;
  }
}

@media screen and (min-width: 481px) and (max-width: 799px) {
  .projectPublication {
    margin-bottom: 20px;
    grid-template-columns: 100fr 90px;
    padding: 10px 15px;
  }

  .projectPublication .projectPublicationTextContainer p {
    font-size: 13px;
  }

  .projectPublication a {
    width: 80px;
    height: 33px;
  }

  .projectPublication a p {
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (min-width: 800px) and (max-width: 1023px) {
  .projectPublication {
    margin-bottom: 20px;
    grid-template-columns: 100fr 125px;
    padding: 20px 30px;
  }

  .projectPublication .projectPublicationTextContainer p {
    font-size: 16px;
  }

  .projectPublication a {
    width: 115px;
    height: 48px;
  }

  .projectPublication a:hover {
    text-decoration: none;
    background: #3f4d97;
  }

  .projectPublication a:hover p {
    color: #ffffff;
  }

  .projectPublication a p {
    font-size: 17px;
    line-height: 25px;
  }
}

@media screen and (min-width: 1024px) {
  .projectPublication {
    margin-bottom: 20px;
    grid-template-columns: 80fr 20fr;
    padding: 20px 30px;
  }
  .projectPublication .projectPublicationTextContainer p {
    font-size: 18px;
  }

  .projectPublication a {
    width: 155px;
    height: 65px;
  }

  .projectPublication a:hover {
    text-decoration: none;
    background: #3f4d97;
  }

  .projectPublication a:hover p {
    color: #ffffff;
  }

  .projectPublication a p {
    font-size: 24px;
    line-height: 36px;
  }
}
