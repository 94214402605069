#research {
  margin-top: 0px;
  margin-bottom: 10px;
  width: 100vw;
  padding: 50px 0 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#research h1 {
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}

@media screen and (max-width: 480px) {
  #research {
    padding-bottom: 20px;
  }

  #research h1 {
    font-size: 22px;
    line-height: 32px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1023px) {
  #research h1 {
    font-size: 35px;
    line-height: 49px;
  }
}

@media screen and (min-width: 1024px) {
  #research h1 {
    font-size: 50px;
    line-height: 70px;
  }
}
