@media screen and (max-width: 699px) {
  .GifBoxContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (min-width: 700px) {
  .GifBoxContainer {
    display: grid;
    grid-template-columns: 50fr 50fr;
    grid-template-rows: 50fr 50fr;
    justify-items: center;
  }
}
