.CourseComponent {
  width: 100%;
  background-color: rgba(39, 43, 61, 0.4);
  border-radius: 28px;
  color: #ffffff;
}

.courseInfo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bubbleContainer {
  display: flex;
}

.courseBubble {
  background: #292e47;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.courseBubble p {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
}

.courseTitleContainer h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
}

.courseDescriptionContainer p {
  text-align: left;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 749px) {
  .CourseComponent {
    width: 90vw;
    margin-bottom: 15px;
  }

  .courseInfo {
    padding: 20px 20px 5px;
  }

  .courseCodeContainer {
    width: 130px;
    height: 38px;
  }

  .courseUnitsContainer {
    width: 100px;
    height: 38px;
    margin-left: 15px;
  }

  .courseBubble p {
    font-size: 12px;
  }

  .courseTitleContainer {
    margin-top: 15px;
  }

  .courseTitleContainer h3 {
    font-size: 16px;
    line-height: 150%;
    font-weight: 600;
  }

  .courseDescriptionContainer {
    margin-top: 10px;
  }

  .courseDescriptionContainer p {
    font-size: 14px;
    line-height: 150%;
  }
}

@media screen and (min-width: 750px) and (max-width: 1023px) {
  .CourseComponent {
    min-width: 650px;
    margin-bottom: 40px;
  }

  .courseInfo {
    padding: 40px;
  }

  .courseBubble {
    width: 184px;
    height: 58px;
  }

  .courseUnitsContainer {
    margin-left: 60px;
  }

  .courseBubble p {
    font-size: 18px;
    line-height: 27px;
  }

  .courseTitleContainer {
    margin-top: 20px;
  }

  .courseTitleContainer h3 {
    font-size: 25px;
    line-height: 36px;
  }

  .courseDescriptionContainer {
    margin-top: 10px;
  }

  .courseDescriptionContainer p {
    font-size: 18px;
    line-height: 36px;
  }
}

@media screen and (min-width: 1024px) {
  .CourseComponent {
    min-width: 900px;
    margin-bottom: 40px;
  }

  .courseInfo {
    padding: 40px;
  }

  .courseBubble {
    width: 184px;
    height: 58px;
  }

  .courseUnitsContainer {
    margin-left: 60px;
  }

  .courseBubble p {
    font-size: 18px;
    line-height: 27px;
  }

  .courseTitleContainer {
    margin-top: 20px;
  }

  .courseTitleContainer h3 {
    font-size: 25px;
    line-height: 36px;
  }

  .courseDescriptionContainer {
    margin-top: 10px;
  }

  .courseDescriptionContainer p {
    font-size: 18px;
    line-height: 36px;
  }
}
