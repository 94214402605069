.calendlyModalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(39, 43, 61, 0.9);
  border-radius: 30px;
}

.closeCalendlyModalButton {
  position: absolute;
  border: 3px solid #ffffff;
  background: rgba(255, 255, 255, 0.23);
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: 0.75s;
}

.closeCalendlyModalButton p {
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  color: #ffffff;
}

@media screen and (max-width: 480px) {
  .calendlyModalContent {
    width: 95vw;
    min-width: 300px;
    height: 85vh;
    padding-top: 20px;
  }

  .closeCalendlyModalButton {
    top: 10px;
    right: 15px;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    border-width: 2px;
  }

  .closeCalendlyModalButton p {
    font-size: 16px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1023px) {
  .calendlyModalContent {
    width: 98vw;
    max-width: 880px;
    height: 80vh;
    padding-top: 0px;
  }

  .closeCalendlyModalButton {
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 12px;
    border-width: 2.5px;
  }

  .closeCalendlyModalButton p {
    font-size: 16px;
  }
}

@media screen and (min-width: 1024px) {
  .calendlyModalContent {
    min-width: 900px;
    width: 55vw;
    min-height: 600px;
    height: 90vh;
    padding-top: 50px;
  }

  .closeCalendlyModalButton {
    top: 10px;
    right: 15px;
    width: 60px;
    height: 60px;
  }

  .closeCalendlyModalButton p {
    font-size: 20px;
  }
  .closeCalendlyModalButton:hover {
    background: #ffffff;
    text-decoration: none;
  }

  .closeCalendlyModalButton:hover p {
    color: rgba(85, 87, 100, 0.8);
  }
}
